<template>
  <div class="x-wrap">
    <div class="x-page-title">
      <h3>藏品详情</h3>
    </div>
    <div class="x-main">
      <el-button
        icon="el-icon-arrow-left"
        type="primary"
        size="small"
        @click="$router.back()"
        >返回</el-button
      >
      <div class="x-q-panel">
        <el-row>
          <el-col :span="8"
            ><div class="x-con-item">
              <label>藏品Id：</label>
              <el-input maxlength="20" disabled v-model="nftInfo.id"></el-input>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8"
            ><div class="x-con-item">
              <label>藏品名称：</label>
              <el-input
                maxlength="20"
                disabled
                v-model="nftInfo.NFTName"
              ></el-input>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8"
            ><div class="x-con-item">
              <label>藏品描述：</label>
              <el-input
                type="textarea"
                rows="6"
                disabled
                v-model="nftInfo.describtion"
              ></el-input>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8"
            ><div class="x-con-item">
              <label>发行数量：</label>
              <el-input disabled v-model="nftInfo.totalCount"></el-input>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8"
            ><div class="x-con-item">
              <label>藏品集：</label>
              <el-input disabled v-model="nftInfo.collectionName"></el-input>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8"
            ><div class="x-con-item">
              <label>品牌方：</label>
              <el-input disabled v-model="nftInfo.brandName"></el-input>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8"
            ><div class="x-con-item">
              <label>发行地址：</label>
              <el-input disabled v-model="nftInfo.brandBlockchain"></el-input>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8"
            ><div class="x-con-item">
              <label>藏品类型：</label>
              <el-input disabled v-model="nftInfo.NFTType"></el-input>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8"
            ><div class="x-con-item">
              <label>序号开始编号：</label>
              <el-input disabled v-model="nftInfo.codeStart"></el-input>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8"
            ><div class="x-con-item">
              <label>发行时间：</label>
              <el-input disabled v-model="nftInfo.NFTTime"></el-input>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8"
            ><div class="x-con-item">
              <label>藏品哈希：</label>
              <el-input
                type="textarea"
                rows="3"
                disabled
                v-model="nftInfo.NFTHash"
              ></el-input>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8"
            ><div class="x-con-item">
              <label>T+N设置：</label>
              <el-input disabled v-model="nftInfo.transferDays"></el-input>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="8">
            <label>藏品\盲盒标签：</label>

            <el-tag
              :key="index"
              v-for="(tag, index) in nftInfo.label"
              :disable-transitions="false"
            >
              {{ tag.name }}
            </el-tag>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <label>藏品特权：</label>
            <el-table :data="nftInfo.rights" stripe style="width: 100%">
              <el-table-column prop="name" label="特权名称"> </el-table-column>
              <el-table-column prop="desc" label="特权描述"> </el-table-column>
              <el-table-column prop="endDate" label="特权截止日期" width="220">
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8" v-if="nftInfo.NFTType === '盲盒'">
            <label>盲盒奖品：</label>
            <el-table :data="nftInfo.prize" stripe style="width: 100%">
              <el-table-column prop="NFTID" label="藏品">
                <template slot-scope="scope">
                  <el-select
                    placeholder="请选择合成藏品"
                    v-model="scope.row.NFTID"
                    disabled
                  >
                    <el-option
                      v-for="(item, index) in optionsNft"
                      :key="index"
                      :label="item.NFTName"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column prop="ceiling" label="数量" align="center">
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </div>
    </div>

    <el-form :model="nftInfo" label-width="110px" ref="myForm" required>
      <el-row>
        <el-form-item label="藏品图片：" prop="license">
          <el-upload
            class="x-single-upload"
            drag
            ref="upload"
            action="#"
            name="file"
            accept=".jpg, .jpeg, .png,.gif"
          >
            <el-image
              v-if="nftInfo.NFTUrl"
              style="width: 360px; height: 180px"
              :src="nftInfo.NFTUrl"
              fit="contain"
            ></el-image>
            <div v-else>
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将文件拖到此处，或
                <em>点击上传</em>
              </div>
            </div>
            <div class="el-upload__tip" slot="tip">
              （支持PNG、JPG或Gif格式图片，图片大小不超过30M）
            </div>
          </el-upload>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="藏品缩略图：" prop="NFTThumbnailUrl">
          <el-upload
            class="x-single-upload"
            drag
            ref="upload"
            action="#"
            name="file"
            accept=".jpg, .jpeg, .png,.gif"
          >
            <el-image
              v-if="nftInfo.NFTThumbnailUrl"
              style="width: 360px; height: 180px"
              :src="nftInfo.NFTThumbnailUrl"
              fit="contain"
            ></el-image>
            <div v-else>
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将文件拖到此处，或
                <em>点击上传</em>
              </div>
            </div>
            <div class="el-upload__tip" slot="tip">
              （支持PNG、JPG或Gif格式图片，图片大小不超过30M）
            </div>
          </el-upload>
        </el-form-item></el-row
      >
      <el-row>
        <el-form-item
          label="音频试听文件："
          prop="NFTThumbnailUrl"
          v-if="nftInfo.NFTType === '音频'"
        >
          <el-upload
            class="x-single-upload"
            drag
            ref="upload"
            action="#"
            name="file"
          >
            <div v-if="nftInfo.NFTShortUrl">
              <span>已上传文件（音频包），点击可试听</span>
              <audio controls>
                <source :src="nftInfo.NFTShortUrl" type="audio/mpeg" />
              </audio>
            </div>
            <div v-else>
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将文件拖到此处，或
                <em>点击上传</em>
              </div>
            </div>
            <div class="el-upload__tip" slot="tip">
              （支持MP3格式的文件，文件大小不超过30M）
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item
          label="视频试看文件："
          prop="NFTShortUrl"
          v-if="nftInfo.NFTType === '视频'"
        >
          <el-upload
            class="x-single-upload"
            drag
            ref="upload"
            action="#"
            name="file"
            accept=".mp4"
          >
            <video
              v-if="nftInfo.NFTShortUrl"
              style="width: 360px; height: 180px"
              :src="nftInfo.NFTShortUrl"
              fit="contain"
              controls
            ></video>
            <div v-else>
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将文件拖到此处，或
                <em>点击上传</em>
              </div>
            </div>
            <div class="el-upload__tip" slot="tip">
              （支持MP4格式的文件，文件大小不超过50M）
            </div>
          </el-upload>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item
          label="藏品原图："
          prop="NFTHDUrl"
          v-if="nftInfo.NFTType === '图片' || nftInfo.NFTType == '盲盒'"
        >
          <el-upload
            class="x-single-upload"
            drag
            ref="upload"
            action="#"
            name="file"
            accept=".jpg, .jpeg, .png,.gif"
          >
            <el-image
              v-if="nftInfo.NFTHDUrl"
              style="width: 360px; height: 180px"
              :src="nftInfo.NFTHDUrl"
              fit="contain"
            ></el-image>
            <div v-else>
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将文件拖到此处，或
                <em>点击上传</em>
              </div>
            </div>
            <div class="el-upload__tip" slot="tip">
              （支持PNG、JPG或Gif格式图片，图片大小不超过30M）
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item
          label="藏品音频完整文件："
          prop="NFTHDUrl"
          v-else-if="nftInfo.NFTType === '音频'"
        >
          <el-upload
            class="x-single-upload"
            drag
            ref="upload"
            action="#"
            name="file"
            accept=".mp3"
            ><div v-if="nftInfo.NFTHDUrl">
              <span>已上传文件（音频包），点击可试听</span>
              <audio controls>
                <source :src="nftInfo.NFTHDUrl" type="audio/mpeg" />
              </audio>
            </div>
            <div v-else>
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将文件拖到此处，或
                <em>点击上传</em>
              </div>
            </div>
            <div class="el-upload__tip" slot="tip">
              （支持MP3格式的文件，文件大小不超过30M）
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item
          label="藏品视频完整文件："
          prop="NFTHDUrl"
          v-else-if="nftInfo.NFTType === '视频'"
        >
          <el-upload
            class="x-single-upload"
            drag
            ref="upload"
            action="#"
            name="file"
            accept=".mp4"
            :show-file-list="false"
            :file-list="NFTHDUrlList"
            :on-change="setNFTHDUrlList"
            :before-upload="beforeVideoUpload"
            :http-request="doNFTHDUrlUpload"
          >
            <video
              v-if="nftInfo.NFTHDUrl"
              style="width: 360px; height: 180px"
              :src="nftInfo.NFTHDUrl"
              fit="contain"
              controls
            ></video>
            <div v-else>
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将文件拖到此处，或
                <em>点击上传</em>
              </div>
            </div>
            <div class="el-upload__tip" slot="tip">
              （支持mp4格式的文件，文件大小不超过50M）
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item
          label="3D模型(.zip)文件："
          prop="NFTShortUrl"
          v-if="nftInfo.NFTType === '3D模型'"
        >
          <el-upload
            class="x-single-upload"
            drag
            ref="upload"
            action="#"
            name="file"
            accept=".obj"
          >
            <a
              class="font-primary"
              v-if="nftInfo.NFTHDUrl"
              :href="nftInfo.NFTHDUrl"
              target="_blank"
              >已上传文件（模型包），点击可下载</a
            >
            <div v-else>
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将文件拖到此处，或
                <em>点击上传</em>
              </div>
            </div>
            <div class="el-upload__tip" slot="tip">
              （支持.obj格式的文件，文件大小不超过100M）
            </div>
          </el-upload>
        </el-form-item></el-row
      >
    </el-form>
  </div>
</template>

<script>
import { NftDetail } from "@/api/module/nft";
import { NftQuery } from "@/api/module/nft";

export default {
  name: "NftDetail",
  props: ["id"],
  data() {
    return {
      nftInfo: {},
      optionsNft: [],
    };
  },
  created() {
    this.getDetail();
    this.getNft();
  },
  methods: {
    getNft() {
      let query = `?pagination=false&page=1&pageSize=10&status=3`;
      NftQuery(query).then((r) => {
        this.optionsNft = r.list;
      });
    },
    async getDetail() {
      const r = await NftDetail(this.id);
      if (r.NFTType === "1") {
        r.NFTType = "图片";
      } else if (r.NFTType === "2") {
        r.NFTType = "音频";
      } else if (r.NFTType === "3") {
        r.NFTType = "视频";
      } else if (r.NFTType === "4") {
        r.NFTType = "3D模型";
      } else if (r.NFTType === "5") {
        r.NFTType = "盲盒";
      }
      this.nftInfo = r;
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-row {
  margin-top: 25px;
}
/deep/ .el-form {
  position: absolute;
  top: 155px;
  right: 350px;
}
/deep/.el-tag + .el-tag {
  margin-left: 10px;
}
.x-wrap {
  position: relative;
  .x-q-panel {
    padding-left: 200px;
    margin-top: 15px;
    min-height: 1200px;
  }
}
</style>